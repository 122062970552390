import { useLocation, useMatch } from "@reach/router"
import algoliasearch from "algoliasearch/lite"
import { graphql, StaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row, Spinner } from "react-bootstrap"
import {
  connectStateResults,
  Hits,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-dom"
import SuggestedStories from "../../../components/blog/landingSuggestedStories"
import NoResult from "../../../components/blog/no-result"
import StoryCard from "../../../components/blog/storyCard"
import Frame from "../../../components/common/frame"
import NavigationBlog from "../../../components/navigationBlog"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_NEW_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_NEW_KEY
)

function BlogSearch() {
  const [param, setParam] = useState("")

  const [searchLoaded, setSearchLoaded] = useState(false)

  const [searchQ, setSearchQ] = useState("")

  const [searchNotMaching, setSearchNotMaching] = useState(false)

  const location = useMatch("/blog/search/:query")

  const Query = useLocation().search.split("=")[1]

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      !searchResults || searchResults.nbHits === 0
        ? setSearchNotMaching(true)
        : setSearchNotMaching(false)
      return searchResults && searchResults.nbHits !== 0 && children
    }
  )

  useEffect(() => {
    if (!!Query) {
      setSearchQ(Query)
    } else {
      setSearchQ("")
    }
  }, [])

  useEffect(() => {
    setTimeout(() => setSearchLoaded(true), 3000)
  }, [])

  return (
    <div className="blog">
      <StaticQuery
        query={graphql`
          query contentBlogSearch {
            SuperOps {
              pages(where: { title: "Blog" }) {
                id
                title
                slug
                pageBlock {
                  id
                  content {
                    html
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <div>
            {data.SuperOps.pages.map(page => {
              const { seo, navigationBlock } = page
              return (
                <>
                  <Frame seo={seo} ogType="blog">
                    <InstantSearch
                      searchClient={searchClient}
                      indexName="blog_search"
                      searchState={{
                        query: searchQ,
                      }}
                    >
                      <header>
                        <NavigationBlog
                          background="#fff2f1"
                          links={navigationBlock}
                        />
                      </header>
                      <section className="main">
                        <div className="blog-search">
                          <Container className="title">
                            <Row>
                              <Col lg={12}>
                                {!!searchQ ? (
                                  <>
                                    <h3>Search results for </h3>
                                    <div className="p18">
                                      <p className="color-red">
                                        "{decodeURIComponent(searchQ)}"
                                      </p>
                                    </div>
                                  </>
                                ) : (
                                  <h3>Search</h3>
                                )}
                              </Col>
                            </Row>
                          </Container>
                          <Container className="shadowbg" />
                          <Container className="content">
                            <SearchBox className="ais-sb" />
                            {!searchLoaded ? (
                              <Spinner animation="border" />
                            ) : !searchNotMaching ? (
                              <Results>
                                <Hits hitComponent={Hit} />
                              </Results>
                            ) : (
                              <>
                                <NoResult />
                                <Container className="suggested-stories nf">
                                  <span className="p16 section-title">
                                    <p>MEAN WHILE READ OUR SUGGESTED STORIES</p>
                                  </span>
                                  <SuggestedStories />
                                </Container>
                              </>
                            )}
                          </Container>
                        </div>
                      </section>
                    </InstantSearch>
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </div>
  )
}

function Hit(props) {
  return (
    <>
      <StoryCard
        title={props.hit.title}
        excerpt={props.hit.excerpt}
        author={props.hit.author}
        coverImage={props.hit.coverImage}
        tags={props.hit.tags}
        readTime={props.hit.readTime}
        slug={props.hit.slug}
        contentEllipseLine="3"
        headingEllipseLine="2"
        cardSize="small"
        contentMinHeight="368px"
        isBlog={props.hit.isBlog}
        style={{ marginBottom: "48px" }}
      />
    </>
  )
}

export default BlogSearch
